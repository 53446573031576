export const SEARCH_FILTER = 1;
export const SEARCH_SAVED_SEARCHES = 2;
export const SEARCH_SAVED_LIST = 3;


export const TYPE_SAVED_SEARCHES = 1;
export const TYPE_SAVED_LISTS = 2;

export const PEOPLE_TYPE = 1;
export const COMPANY_TYPE = 2;
export const INTENT_TYPE = 4;
export const EMPLOYEE_TYPE = 5;
export const SEQUENCE_PROSPECT_TYPE = 6

export const PAGE_MAP = {
  [COMPANY_TYPE]: 'company',
  [PEOPLE_TYPE]: 'people',
  [INTENT_TYPE]: 'intent',
  [EMPLOYEE_TYPE]: 'intentEmployee',
  [SEQUENCE_PROSPECT_TYPE]: 'sequence'
}

export const TYPE_ADD = 1;
export const TYPE_EDIT = 2;

export const SAVED_LIST_SAVE = 1;
export const SAVED_LIST_UNSAVE = 2;

export const PAGE_SELECT_ALL = 1;
export const PAGE_SELECT_THIS_PAGE = 2;
export const PAGE_SELECT_THIS_CUSTOMIZE = 3;

export const REDEEM_EMAIL = 1;
export const REDEEM_PHONE = 2;
export const REDEEM_EMIAL_AND_PHONE = 3;

export const NO_LOGO_URL = "https://leads.pubrio.com/img/no_logo.png";

export const CATEGORY_ALL = 'all';
export const CATEGORY_MY = 'private';
export const CATEGORY_TEAM = 'team';

export const categoryMap = new Map([
  [CATEGORY_ALL, null],
  [CATEGORY_MY, true],
  [CATEGORY_TEAM, false],
]);


export const PAGE_COMPANY = 2;

export const FILTER_REMOVED = 'Filter removed';
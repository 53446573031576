export default {
    "understandingCreditConsumption": "了解积分消费",
    "howCreditsAreUsed": "如何使用积分：",
    "creditsAreConsumed": "每当您在平台上执行某些操作时，如搜索潜在客户、兑换电子邮件或导出数据，都会消耗积分。每个操作都有一个特定的信用成本，将从您的账户余额中扣除。下表显示了每个动作如何使用积分的细分。",
    "actionFeature": "动作/功能",
    "creditsConsumed": "已消耗的积分",
    "cumulativeUsage": "累计使用量",
    "details": "详情",
    "searchingForLeadsConsumes": "搜索潜在客户每次搜索会消耗 {count} 个积分。",
    "redeemingAnEmail": "兑换电子邮件每次兑换使用 {count} 积分。",
    "addingAContact": "将联系人添加到列表中会消耗 {count} 个积分。",
    "exportingLeadsToACSV": "将潜在客户导出到CSV将使用 {count} 积分。",
    "identifyWebVisitors": "{count} 积分将用于识别网络访问者。",
    "gotIt": "知道了！",
    "featureSearch": "搜索",
    "featureRedeemEmail": "兑换电子邮件",
    "featureRedeemPhone": "兑换手机",
    "featureExportLeads": "导出潜在客户",
    "featureWebIdentification":"网络标识"
};
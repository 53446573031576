const tableEmptyInfomationCNSimplify = {
  "-table-empty-title": "暂无数据",
  "-table-empty-desc": "",
  "sequence-list-table-empty-title": "尚未创建工作流程",
  "sequence-list-table-empty-desc":
    "根据您的需求设计工作流程，开始构建您的销售策略。点击 [+ 新工作流程] 开始操作",
  "sequence-detail-inbox-table-empty-title": "未发送任何信息",
  "sequence-detail-inbox-table-empty-desc":
    "通过监控工作流程的信息发送情况，确保高效通信。目前，此工作流程尚未发送任何信息。",
  "setting-notification-table-empty-title": "暂无通知",
  "setting-notification-table-empty-desc":
    "看来暂时没有新消息，请稍后查看更新和通知。",
  "setting-billing-history-table-empty-title": "无账单历史记录",
  "setting-billing-history-table-empty-desc":
    "看起来您还没有付款，一旦您开始使用我们的付费功能，您的账单历史记录就会显示在这里。",
  "setting-role-table-empty-title": "未创建角色",
  "setting-role-table-empty-desc":
    "角色可帮助你管理团队内部的权限，创建角色来定义用户的访问级别。",
  "setting-roles-users-table-empty-title": "未指定用户",
  "setting-roles-users-table-empty-desc":
    "该角色目前没有指定用户。开始添加团队成员，定义他们的职责并简化工作流程。",
  "setting-team-table-empty-title": "未创建团队",
  "setting-team-table-empty-desc":
    "通过团队，您可以组织不同的小组并与之协作。创建一个新团队即可开始工作。",
  "setting-teams-users-table-empty-title": "未指定用户",
  "setting-teams-users-table-empty-desc":
    "该角色目前没有指定用户。开始添加团队成员，定义他们的职责并简化工作流程。",
  "setting-users-table-empty-title": "未添加用户",
  "setting-users-table-empty-desc":
    "用户一旦添加，就会出现在这里。您可以邀请团队成员加入您的平台，开展协作工作。",
  "setting-custom-field-table-empty-title": "尚无自定义联系字段",
  "setting-custom-field-table-empty-desc": "",
  "setting-api-keys-table-empty-title": "未生成 API 密钥",
  "setting-api-keys-table-empty-desc":
    "API 密钥允许您与外部服务集成。创建一个新的 API 密钥，开始连接您的系统。",
};

export default tableEmptyInfomationCNSimplify;

const settingApiKeysCNSimplify = {
  settings_api_keys_api_docs: "更多信息 - API",
  settings_apikeys_create_dialog_title: "创建新的 API 密钥",
  settings_apikeys_create_dialog_desc:
    "创建一个新的 API 密钥以便外部系统访问您的账户。",
  settings_apikeys_form_name_placeholder: "输入 API 密钥名称",
  settings_apikeys_form_description_placeholder: "输入密钥用途的简短描述",
  settings_apikeys_form_services_placeholder: "选择与此密钥关联的服务",
  settings_api_keys_api_tip:
    "如果您没有为此新密钥选择任何 API，我们将假设此新密钥为‘主密钥’",
  settings_api_keys_api_set_master_key: "设置为主密钥",
  "API Keys": "API 密钥",
  API_Service: "服务",
  usage_total_this_month: "本月总调用次数：",
  usage_remaining_quota: "剩余配额：",
  usage_reset_on: "重置于：",
  call: "次调用 | 次调用 | 次调用",
  _usage_sub_title: "使用量",
  portal_usage_sub_title: "查询上限",
  hourly: "每小时",
  daily: "每日",
  monthly: "每月",
  usage_portal_title: "门户限额",
  usage_api_title: "API",
};

export default settingApiKeysCNSimplify;

const tableEmptyInfomationCNTraditional = {
  "-table-empty-title": "目前尚無資料",
  "-table-empty-desc": "",
  "sequence-list-table-empty-title": "尚未創建工作流程",
  "sequence-list-table-empty-desc":
    "根據您的需求設計序列，開始建立您的銷售策略。按一下 [+ 新工作流程] 即可開始。",
  "sequence-detail-inbox-table-empty-title": "尚未傳送訊息",
  "sequence-detail-inbox-table-empty-desc":
    "透過監控工作流程的訊息傳送，確保有效率的通訊。目前，沒有訊息透過工作流程傳送。",
  "setting-notification-table-empty-title": "暫無通知",
  "setting-notification-table-empty-desc":
    "看起來暫時沒有任何新消息，請稍後再回來查看更新和通知。",
  "setting-billing-history-table-empty-title": "尚未有帳單記錄",
  "setting-billing-history-table-empty-desc":
    "看起來您還沒有付款。一旦您開始使用我們的付費功能，您的帳單歷史記錄將會顯示在這裡。",
  "setting-role-table-empty-title": "無角色建立",
  "setting-role-table-empty-desc":
    "角色可協助您管理團隊內的權限，建立角色來定義使用者的存取等級。",
  "setting-roles-users-table-empty-title": "沒有指派使用者",
  "setting-roles-users-table-empty-desc":
    "此角色目前沒有指定使用者。開始新增團隊成員，定義他們的職責並簡化工作流程。",
  "setting-team-table-empty-title": "未建立團隊",
  "setting-team-table-empty-desc":
    "團隊可讓您與不同的群組組織和協作，從建立一個新團隊開始。",
  "setting-teams-users-table-empty-title": "沒有指派使用者",
  "setting-teams-users-table-empty-desc":
    "此角色目前沒有指定使用者。開始新增團隊成員，定義他們的職責並簡化工作流程。",
  "setting-users-table-empty-title": "無使用者新增",
  "setting-users-table-empty-desc":
    "使用者加入後會出現在這裡。您可以邀請團隊成員加入您的平台進行協同工作。",
  "setting-custom-field-table-empty-title": "尚未有自訂聯絡欄位",
  "setting-custom-field-table-empty-desc": "",
  "setting-api-keys-table-empty-title": "未產生 API 金鑰",
  "setting-api-keys-table-empty-desc":
    "API 金鑰可讓您與外部服務整合。建立新的 API 金鑰，開始連接您的系統。",
};

export default tableEmptyInfomationCNTraditional;

export default {
    "understandingCreditConsumption": "瞭解積分消費",
    "howCreditsAreUsed": "如何使用積分：",
    "creditsAreConsumed": "每當您在平臺上執行某些操作時，如蒐索潛在客戶、兌換電子郵件或匯出數據，都會消耗積分。 每個操作都有一個特定的信用成本，將從您的帳戶餘額中扣除。 下錶顯示了每個動作如何使用積分的細分。",
    "actionFeature": "動作/功能",
    "creditsConsumed": "已消耗的積分",
    "cumulativeUsage": "累計使用量",
    "details": "詳情",
    "searchingForLeadsConsumes": "蒐索潛在客戶每次蒐索會消耗 {count} 個積分。",
    "redeemingAnEmail": "兌換電子郵件每次兌換使用 {count} 積分。",
    "addingAContact": "將連絡人添加到清單中會消耗 {count} 個積分。",
    "exportingLeadsToACSV": "將潛在客戶匯出到CSV將使用 {count} 積分。",
    "identifyWebVisitors": "{count} 積分將用於識別網絡訪問者。",
    "gotIt": "知道了！",
    "featureSearch": "蒐索",
    "featureRedeemEmail": "兌換電子郵件",
    "featureRedeemPhone": "兌換手機",
    "featureExportLeads": "匯出潛在客戶",
    "featureWebIdentification":"網絡標識"
};
export default {
    "understandingCreditConsumption": "Understanding Credit Consumption",
    "howCreditsAreUsed": "How Credits Are Used:",
    "creditsAreConsumed": "Credits are consumed whenever you perform certain actions on the platform, like searching for leads, redeeming emails, or exporting data. Each action has a specific credit cost, which will be deducted from your account balance. The table below shows a breakdown of how each action uses credits.",
    "actionFeature": "Action/Feature",
    "creditsConsumed": "Credits Consumed",
    "cumulativeUsage": "Cumulative Usage",
    "details": "Details",
    "searchingForLeadsConsumes": "Searching for leads consumes {count} credit per search. | Searching for leads consumes {count} credits per search.",
    "redeemingAnEmail": "Redeeming an email uses {count} credit per redemption. | Redeeming an email uses {count} credits per redemption.",
    "addingAContact": "Adding a contact to your list consumes {count} credit. | Adding a contact to your list consumes {count} credits.",
    "exportingLeadsToACSV": "Exporting leads to a CSV will use {count} credit. | Exporting leads to a CSV will use {count} credits.",
    "identifyWebVisitors": "{count} credit will be used to identify web visitors. | {count} credits will be used to identify web visitors.",
    "gotIt": "Got It!",
    "featureSearch": "Search",
    "featureRedeemEmail": "Redeem Email",
    "featureRedeemPhone": "Redeem phone",
    "featureExportLeads": "Export Leads",
    "featureWebIdentification":"Web Identification"
};
import * as API from "@/api/apiKeys";

const state = {
  apiKeysList: {},
  loading: false,
};

const mutations = {
  setApiKeysList(state, data) {
    state.apiKeysList = data;
  },
  setLoading(state, data) {
    state.loading = data;
  },
};

const actions = {
  async getApiKeysList(context, data) {
    try {
      context.commit("setLoading", true);
      const id = this.state.user.currentId.profile_id;
      let params = {
        profile_id: id,
        page: 1,
        per_page: 25,
      };
      params = { ...params, ...data };
      const res = await API.getApiKeysList(params);
      context.commit("setApiKeysList", res?.data);
      return res?.data;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      context.commit("setLoading", false);
    }
  },
  async createApiKey(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      let params = {
        profile_id: id,
        ...data,
      };
      const res = await API.createApiKey(params);
      return res?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async revealApiKey(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      let params = {
        profile_id: id,
        ...data,
      };
      const res = await API.revealApiKey(params);
      return res?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteApiKey(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      let params = {
        profile_id: id,
        ...data,
      };
      const res = await API.deleteApiKey(params);
      return res?.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import { notificationTraditional } from "./notification/index.js";
import sequence from "./sequence/zh-tw.js";
import base from "./base/zh-tw.js";
import search from "./search/zh-tw.js";
import services from "./services/zh-tw.js";
import intent from "./intent/zh-tw.js";
import tableEmptyInfomationCNTraditional from "./common/table-empty-infomation/zh-tw.js";
import settingApiKeysCNTraditional from "./settings/api-keys/zh-tw.js";
import globalMessageChineseTraditional from "./common/global-message/zh-tw.js";
import pricing from './settings/pricing/zh-tw.js'
export default {
  //login
  loading: "加載中...",
  SignUp: "註冊",
  LoginSuccess: "成功登錄",
  LoginOut: "成功登出",
  email: "電子郵件",
  password: "密碼",
  logIn: "登錄",
  WelcomeBack: "歡迎回來",
  PleaseEnterYourEmailAndPassword: "請輸入您的電子郵件和密碼",
  CharactersRequired: "需要8個以上字符",
  keepMeSignedIn: "保持我登錄狀態",
  forgetPassword: "忘記密碼",
  dontHaveAnAccount: "還沒有帳戶？",
  signUpNow: "立即註冊",
  AllRightsReserved: "版權所有",
  privacyPolicy: "隱私政策",
  and: "和",
  termsOfService: "服務條款",
  Name: "姓名",
  viewAll: "查看全部",
  fold: "折疊",
  updateCreditCard: "更新信用卡",
  role: "角色",
  noLimit: "無限制",

  //signup
  company: "公司",
  companyName: "公司名稱",
  signUp: "註冊",
  alreadyHaveAnAccount: "已經有帳戶？",
  getStarted: "開始使用！用我們的平台賦能您的業務",
  signUpSuccess: "成功註冊",

  //forget
  noWorries: "不用擔心，輸入您的註冊電子郵件，我們將發送重置說明",

  //reset
  reset: "重置密碼",
  forgotPassword: "忘記密碼",
  RequestPasswordReset: "請求重置密碼",
  backToLogin: "返回登錄",
  verifyPassword: "驗證密碼",
  EnterANewPasswordFor: "為 example@gmail.com 輸入新密碼",
  ResetPassword: "重置密碼",
  newPassword: "新密碼",

  //not available
  ComingSoon: "敬請期待！",
  TheFeatureIscurrentlyUnder: "該功能目前正在",
  development: "開發中",
  weAppreciateYourPatienceAsWeWorkToImprove: "我們感謝您在我們努力改善",
  yourExperience: "您的體驗時的耐心。",
  inTheMeantimeIfYouHaveAnyQuestionsOrNeed: "在此期間，如果您有任何問題或需要",
  assistancePleaseContactOurSupportTeam: "協助，請聯繫我們的支持團隊。",
  contactSupport: "聯繫支持",
  ok: "確定",
  thankYouForBeingAValuedUserOfOurPlatform: "感謝您成為我們平台的珍貴用戶。",

  //search-company
  people: "潛在客戶",
  companies: "公司",
  clearAll: "清除所有",
  searchProspects: "搜索潛在客戶、關鍵字...",
  //actions
  isFoundIn: "在 ",
  isNotFoundWithinAnyLists: "未在任何列表中找到",
  l: " 列表",
  remove: "移除",
  saveToLists: "保存到列表",
  DeleteFailed: "刪除失敗",
  //delete dialog
  areYouSureYouWantToDeleteThisRecord: "您確定要刪除此記錄嗎？",
  proceedingWithDeletionWillResultInPermanentRemovalOfTheList:
    "進行刪除將導致該列表永久移除。",
  delete: "刪除",
  byCancelingYouEnsureThatNoListsWillBeRemoved:
    "通過取消，您可以確保不會移除任何列表。",
  //export dialog
  holdOnWereGeneratingYourExport: "請稍候，我們正在生成您的導出文件。",
  UseThisButtonOnlyIfYouWishToCancelYourAction:
    "僅在您希望取消操作時使用此按鈕。",
  //save search pop
  saveSearch: "保存搜索",
  newSaveSearch: "新保存搜索",
  UpdateSavedSearch: "更新已保存搜索",
  enterNewSearchName: "輸入新搜索名稱",
  visibility: "可見性",
  manageWhoCanSeeYourSavedSearch: "管理誰可以查看您的已保存搜索",
  private: "私有",
  onlyYouCanSeeYourSavedSearch: "只有您可以查看您的已保存搜索",
  everyoneCanSeeYourSavedSearch: "每個人都可以查看您的已保存搜索",
  pinSavedSearch: "釘選已保存搜索",
  findSavedSearch: "查找已保存的搜索",
  enterSavedSearchName: "輸入已保存搜索名稱",
  duplicate: "重複",
  update: "更新",
  RemoveCreditCard: "移除信用卡",
  enterATitleForYourCard: "為您的卡片輸入標題",
  enterYourCardNumber: "輸入您的卡號",
  digit: "3位數",
  provideYourCompleteAddress: "提供您的完整地址",
  selectYourCountry: "選擇您的國家",
  //add credits pop
  ClickTheButtonBelowToAddMoreCredits: "點擊下面的按鈕以添加更多信用點數！",
  PleaseEnterTheAmountYouLikeToTopUpToYourCredit: "請輸入您希望充值的金額",
  //companyLink\contactBtn\functionBtn
  more: "更多",
  verified: "已驗證",
  GetEmailSuccessfully: "成功獲取電子郵件",
  CopySuccessfully: "成功複製",
  recorded: "已記錄",
  implement: "實施",
  sequence: "工作流程",
  of: "  總條數：",
  toAssistYouEffectivelyWeRequireFurtherDetailsAbout:
    "為了有效協助您，我們需要進一步了解您的搜索偏好。請利用左側面板中的篩選選項來指定您所需的標準，以便我們能夠精煉搜索結果，並為您提供符合要求的目標。",
  //filter
  all: "所有",
  my: "我的",
  team: "團隊",
  lists: "列表",
  employees: "員工",
  technologies: "技術",
  socialmedia: "社交媒體",
  enterSocialMedia: "輸入社交媒體",
  noResultsFound: "未找到結果",
  searchCompanies: "搜索公司",
  filters: "篩選器",
  enterCompanies: "輸入公司名稱",
  accountHqLocation: "帳戶總部位置",
  CityStateCountryZIP: "城市/州/國家/郵政編碼",
  enterLocations: "輸入地點",
  revenue: "收入",
  chooseARangeOfRevenue: "選擇收入範圍",
  ofRevenueIsUnknown: "收入未知",
  technology: "技術",
  category: "類別",
  categories: "類別",
  enterCategories: "輸入類別",
  enterTechnologies: "輸入技術",
  enterIndustries: "輸入行業",
  employee: "員工",
  predefinedRange: "預定義範圍",
  ofEmployeesIsUnknown: "員工數量未知",
  enterNames: "輸入姓名",
  jobTitle: "職位名稱",
  searchForAJobTitle: "搜索職位名稱",
  founded: "成立於",
  chooseARangeOfFoundedTime: "選擇成立時間範圍",
  from: "從",
  to: "到",
  specialties: "專業領域",
  enterSpecialties: "輸入專業領域",
  location: "地點",
  total: "總計",
  department: "部門",
  EnterDepartments: "輸入部門",
  JobTitle: "職位名稱",
  EnterJobTitles: "輸入職位名稱",
  ManagementLevel: "管理層級別",
  EnterManagementLevels: "輸入管理層級別",
  AccountHQ: "帳戶總部",
  ContactHQ: "聯絡總部",
  departmentFunction: "部門功能",
  EnterJobFunctions: "輸入職務功能",
  enterNamesAndPressTheEnterKey: "輸入姓名並按Enter鍵...",
  EnterJobTitlesAndPressTheEnterLKey: "輸入職位並用“,”分隔，然後按Enter鍵...",
  pleaseSelectRange: "請選擇範圍",

  //table
  selectAllCompanies: "選擇所有公司",
  selected: "已選擇",
  export: "導出",
  industry: "行業",
  keywords: "關鍵字",
  companyLocation: "公司位置",
  action: "操作",
  PleaseEnterTheSelectedQuantity: "請輸入所選數量",
  contact: "聯絡人",
  Title: "職稱",
  selectThisPage: "選擇此頁面",
  clearSelection: "清除選擇",
  selectNumberOfCompanies: "選擇公司數量",
  maxCompanies: "最多公司數量",
  youCanOnlySelectAMaximumOf25Companies: "您最多只能選擇25家公司。",
  UpgradeYourPlanNow: "立即升級您的計劃！",
  viewPlans: "查看計劃",
  applySelection: "應用選擇",
  selectNumberOfProspects: "選擇潛在客戶數量",
  maxProspects: "最多潛在客戶數量",
  youCanOnlySelectAMaximumOf25Prospects: "您最多只能選擇25個潛在客戶。",
  PleaseSelectTheDataToBeExported: "請選擇要導出的數據",
  overview: "概覽",
  genericContacts: "通用聯絡人",
  size: "規模",
  contactLocation: "聯絡人位置",
  addYourCreditCardInformation: "添加您的信用卡信息",

  //account
  addCreditCard: "添加信用卡",
  zip: "郵政編碼",
  country: "國家",
  state: "州",
  city: "城市",
  address: "地址",
  billingInformation: "帳單信息",
  yourTeams: "您的團隊",
  cvc: "CVC",
  year: "年",
  month: "月",
  expirationDate: "到期日",
  creditCardNumber: "信用卡號碼",
  cardInformation: "卡片信息",
  title: "職稱",
  fullName: "全名",
  yourDetail: "您的詳細信息",
  creditsToUse: "可用信用點數。",
  youHave: "您擁有",
  download: "下載",
  buyMoreCredits: "購買更多信用點數",
  description: "描述",
  topUpCredits: "充值信用點數",
  cancel: "取消",
  date: "日期",
  invoiceHistory: "發票歷史",
  confirmNewPass: "確認新密碼",
  creditsUsed: "已使用信用點數",
  newPass: "新密碼",
  youHaveNotEnteredAnyCreditCardInformationYet: "您尚未輸入任何信用卡信息。",
  oldPass: "舊密碼",
  cardNumber: "卡號：",
  changePassword: "更改密碼",
  creditUsage: "信用點數使用情況",
  typeTheNewTitle: "輸入新職稱",
  save: "保存",
  addMoreCredits: "添加更多信用點數",
  featureRequest: "功能請求",
  creditsMo: "{credit} 信用點數/月",
  apiIntegrations: "API 集成",
  profileSubscription: "個人資料訂閱",
  "additional-user-licence": "額外用戶許可證",
  "agency-white-label": "代理商白標",
  "api-extended-limit": "API 擴展限制",
  "profile-subscription": "個人資料訂閱",
  creditsInYourPlan: "計劃中的信用點數",
  plugin: "插件",
  settings: "設置",
  phone: "電話",
  "1User": "1個用戶",
  users: "用戶",
  billing: "帳單",
  monthlyQueryLimit: "每月查詢上限",
  price: "價格",
  whatsIncluded: "包含內容",
  givenName: "名",
  profile_teams: "個人資料與團隊",
  purchasePlan: "購買計劃",
  accountOverview: "帳戶概覽",
  familyName: "姓氏",
  dailyQueryLimit: "每日查詢上限",
  rateLimit: "查詢限額",
  youAreOnA: "您正在使用一個",
  PlanAndYourCreditsWillRefreshOn: "計劃，您的信用點數將在以下日期刷新",
  过期: "",
  searchSettings: "搜索設置...",
  plan: "計劃",
  accountInfo: "帳戶信息",
  creditCardInformation: "信用卡信息",
  apiUsage: "API 使用情況",
  mo: "/月",
  perUserPerMonth: "每位用戶每月",
  billedAnnually: "按年計費",
  dailyQuery: "每日查詢",
  monthlyQuery: "每月查詢",
  currentPlan: "當前計劃",
  everythingInPlus: "所有加值內容",
  mostPopular: "最受歡迎的",
  topUpToIncreaseYourCredits: "充值以增加您的信用點數",
  addCredits: "添加信用點數",
  allPlansInclude: "所有計劃均包括",
  Amount: "金額",
  credits: "信用點數",
  purchaseSuccess: "成功購買信用點數，您的總信用點數為 ",
  Loading: "加載中...",
  Selected: "已選擇",
  SelectPlan: "選擇計劃",
  currentPlan: "當前訂閱",
  upgradeSuccess: "計劃切換成功",
  purchaseFailed: "購買失敗",
  upgradeFailed: "計劃切換失敗",
  inviteSentSuccessfully: "邀請已成功發送",
  DeleteSuccessfully: "刪除成功",
  AccountupdatedSuccessfully: "帳戶更新成功",
  AccountUpdateFailed: "帳戶更新失敗",
  PasswordUpdatedSuccessfully: "密碼更新成功",
  PasswordUpdateFailed: "密碼更新失敗",
  teamDetails: "團隊詳細信息",
  teamName: "團隊名稱",
  changeTeam: "更改團隊",
  clickToChangeTeam: "點擊以更改團隊",
  changeTeamSuccessfully: "成功更改團隊",
  status: "狀態",
  Action: "操作",
  pending: "待處理",
  current: "當前",
  AreYouSureYouWantToDeleteThisCreditCard: "您確定要刪除此信用卡嗎？",
  AreYouSureYouWantToDeleteThisTeamMember: "您確定要刪除此團隊成員嗎？",
  PasswordCannotBeEmpty: "密碼不能為空",
  newUser: "新用戶",
  deleteUser: "刪除用戶",
  nickname: "暱稱",
  admin: "管理員",
  inviteYourTeam: "邀請您的團隊",
  empowerEveryoneToGetMoreDealsDonefaster: "賦能每個人更快地完成更多交易",
  addMultipleEmailAddressesSeparatedByCommas:
    "添加多個電子郵件地址，以逗號分隔",
  sendInvites: "發送邀請",
  FirstName: "名字",
  LastName: "姓氏",
  timezone: "時區",
  contactNo: "聯繫電話",
  controlYourTeamMembersAndTheirAccountAccessRightHere:
    "在此控制您的團隊成員及其帳戶訪問權限。",
  pleaseEnterYourCurrentPasswordToChangeYourPassword:
    "請輸入您的當前密碼以更改密碼。",
  updatePassword: "更新密碼",
  oldPassword: "舊密碼",
  confirmNewPassword: "確認新密碼",
  InviteYourTeam: "邀請您的團隊",
  empowerEveryoneToGetMoreDealsDoneFaster: "賦能每個人更快地完成更多交易。",
  enterEmailAddressesMultipleCommaseparated:
    "輸入電子郵件地址（多個以逗號分隔）",
  invoice: "發票",
  accountAdmin: "帳戶管理員",
  setAsDefault: "設為默認",
  bankName: "銀行名稱",
  expiry: "到期日",
  viewAllYourPreviousInvoicesHere: "在此查看您所有的歷史發票。",
  BillingHistory: "帳單歷史",
  cardInfo: "付款方式",
  updateYourCreditCardInformation: "更新您的信用卡信息。",
  ManageCreditCard: "管理信用卡",
  CardNumber: "卡號",
  billingAddress: "帳單地址",
  yourCreditsWillRefreshOn: "您的信用點數將在以下日期刷新",
  yourCreditsWillBeChargeOn: "您的信用點數將在以下日期收費",
  被收取: "",
  perYear: "/年",
  perMonth: "/月",
  monthlyCredit: "每月信用點數",
  Usage: "使用情況",
  features: "功能",
  credits_mo: "信用點數/月",
  credits_year: "信用點數/年",
  _mo: "/月",
  Quantity: "數量",
  seeOtherPlan: "查看其他計劃",
  subscriptionTiers: "訂閱層級",
  experiencePricingThatsEasyToUnderstandAndAdaptable:
    "體驗易於理解和適應您需求的定價。",
  monthlyBilling: "每月計費",
  annualBilling: "年度計費",
  forPlanCustomizationContactOurSalesTeam:
    "如需計劃定制，請聯繫我們的銷售團隊。",
  billedAnnually_user: "按年計費/用戶",
  seePlanComparison: "查看計劃比較",
  topUp: "充值",
  changeTeamNameFailed: "更改團隊名稱失敗",
  // purchaseCreditsAtTheRateOf100PerCreditCustomizeyourCreditBundleBasedOnYourNeeds: '按1美元/信用點數購買信用點數，請根據您的需求定制信用點數包',
  totalPrice: "總價格",
  credit: "信用點數",
  collapse: "折疊",
  expand: "展開",

  // analytic
  showNumber: "顯示數字",
  Website: "網站",
  b2bTraffic: "B2B 流量",
  b2bReturningVsNew: "B2B 回訪與新訪客",
  visitors: "訪客",
  totalView: "總瀏覽量",
  totalSessions: "總會話數",
  operatingSystem: "操作系統",
  browser: "瀏覽器",
  device: "設備",
  companySize: "公司規模",
  topIndustry: "主要行業",
  topCountries: "主要國家",
  topReferrals: "主要推薦來源",
  new: "新訪客",
  returning: "回訪者",
  countriesStatistics: "國家統計",
  topCompaigns: "主要活動",
  topPagesByVisits: "按訪問量排序的頁面",
  topPagesByEngagement: "按互動量排序的頁面",
  Referrals: "推薦來源",
  Pages: "頁面",
  avgTime: "平均時間",
  totalCompanies: "總公司數",
  b2bPageViews: "B2B 頁面瀏覽量",
  TotalSessions: "總會話數",
  viewCompanyList: "查看公司列表",
  noDiscussionsYet: "尚無討論",
  useThisSpaceToAskQuestions:
    "您可以使用此空間提出問題、獲取更新，並直接與我們討論詳情",
  // mycontact
  prospects: "潛在客戶",
  list: "列表",
  view: "查看",
  add: "添加",
  newList: "新列表",
  savedLists: "已保存列表",
  searchList: "搜索列表名稱",
  owner: "擁有者",
  records: "記錄",
  actions: "操作",
  lastModified: "最後修改時間",
  name: "名稱",
  enterAListName: "輸入列表名稱",
  pleaseSelectAListFirst: "請先選擇一個列表",
  saveSuccessfully: "保存成功",
  pleaseEnterAListName: "請輸入列表名稱",
  editList: "編輯列表",
  fromThisList: "從此列表中。您確定要繼續嗎？",
  areYouSureYouWantToChange: "您確定要將列表類型更改為",

  // yes: '是',
  // no: '否',
  noListCreated: "未創建列表",
  enhanceTheOrganization:
    "增強您在目標市場內聯絡人和帳戶的組織。創建列表後，它們將顯示在此頁面上。",
  createSuccessfully: "創建成功",
  removeSuccessfully: "移除成功",
  createNewList: "創建新列表",
  groupTeam: "團隊",
  groupPersonal: "個人",
  edit: "編輯",
  areYouSureYouWantToDeleteTheList: "您確定要刪除該列表嗎？",
  proceedingWithDeletion: "進行刪除將導致該列表永久移除。",
  byCanceling: "通過取消，您可以確保不會移除任何列表。",
  byCancelingYouEnsureThatNo: "通過取消，您可以確保不會移除任何",
  willBeRemoved: "將被移除",
  groupPeople: "聯絡人",
  groupCompany: "公司",
  myList: "我的列表",
  teamList: "團隊的列表",
  resetAssured: "請放心，您可以根據需要在不同類型之間切換。",
  wontBeChanged: "通過取消，您可以確保此列表類型不會更改",
  priority: "優先級",
  proceedingWithDeletionWillResultInPeopleDelete:
    "進行刪除將導致聯絡人永久移除",
  proceedingWithDeletionWillResultInCompanyDelete: "進行刪除將導致公司永久移除",
  areYouSureYouWantToRemoveCompanyFromList: "您確定要從列表中移除公司嗎？",
  areYouSureYouWantToRemovePeopleFromList: "您確定要從列表中移除聯絡人嗎？",
  groupExisted: "該組已存在",
  sorryTheListIsCurrrntlyOpen: "抱歉，該列表目前是開放的。您確定要刪除它嗎？",
  listName: "名稱",
  pleaseEnterAValidSiteUrl: "請輸入有效的網站網址",

  // market-intent
  comingSoon: "即將推出",
  weAreCurrentlyWorkingOnThisFeature: "我們目前正在開發此功能，將很快推出",
  stayConnectedWithUs: "通過我們的社交媒體渠道保持聯繫，以接收更新。",
  selectAWebsite: "選擇一個網站",

  // sequence
  Sequences: "工作流程",
  label: "標籤",
  template: "模板",
  folder: "文件夾",
  single: "單一",
  group: "群組",
  steps: "步驟",
  sizes: "大小",
  time: "時間",
  //email
  SendATestEmail: "發送測試電子郵件",
  Categories: "類別",
  addToCategory: "添加到類別",
  EnterOrCreateCategories: "輸入或創建類別",
  addSuccessfully: "添加成功",
  Channels: "渠道",
  emails: "電子郵件",
  enterChannel: "輸入渠道",
  CategoryName: "類別名稱",
  PleaseEnterANewCategoryName: "請輸入新類別名稱：",
  EnterANewCategoryName: "輸入新類別名稱",
  Steps: "步驟",
  Actions: "操作",
  Time: "時間",
  From: "來自",
  To: "至",
  CancelSequenceCreation: "取消工作流程創建",
  AreYouSureYouWantToCancelTheSequenceCreationProcess:
    "您確定要取消工作流程創建過程嗎？",
  SaveAsDraft: "保存為草稿",
  CancelSequence: "取消工作流程",
  ByCancelingAllSettingsWillBeLost:
    "通過取消，您所做的所有設置和進度將會丟失。",
  ImportContacts: "導入聯絡人",
  PleaseUploadACSVFileByDraggingAndDroppingItHere:
    "請通過拖放CSV文件到此處上傳",
  ChooseAFile: "選擇文件",
  CSVFileFields: "CSV文件字段",
  ReplyFields: "回覆字段",
  continue: "繼續",
  Contacts: "聯絡人",
  Back: "返回",
  Import: "導入",
  Imported: "已導入",
  Skipped: "已跳過",
  Done: "完成",
  NewCustomField: "新自定義字段",
  EnterNewLabel: "輸入新標籤",
  EnterNewType: "輸入新類型",
  removeRecorded: "移除記錄",
  YouHaveUnsavedChanges: "您有未保存的更改",
  LeavingDiscard: "離開此頁面將丟棄所有未保存的內容。您確定要繼續嗎？",
  StayPage: "留在此頁面",
  LeavePage: "離開此頁面",
  NewCategory: "新類別",
  Create: "創建",
  UnlockingSequence: "解鎖此工作流程使您能夠進行修改",
  ModifyingLeadDeselection: "修改此內容將導致當前模板版本的取消選擇",
  Modify: "修改",
  CancelingPreserveCurrent: "取消將保留當前版本",
  rename: "重新命名",
  CreatNewLabel: "創建新標籤",
  NewLabel: "新標籤",
  PleaseEnterANewLabelName: "請輸入新標籤名稱：",
  EnterANewLabelName: "輸入新標籤名稱",
  SequenceName: "工作流程名稱",
  PleaseProvideNameForNewSequence: "請提供新工作流程的名稱",
  EnterNewSequenceName: "輸入新工作流程名稱",
  SetUpEmail: "設置電子郵件帳戶以發送",
  NewSchedule: "新日程",
  PleaseEnterANewScheduleName: "請輸入新日程名稱：",
  EnterNewScheduleName: "輸入新日程名稱",
  AddNewTemplate: "添加新模板",
  RemoveFromTemplate: "從模板中移除",
  deleteSuccess: "刪除成功",
  removeSuccess: "移除成功",
  GroupName: "群組名稱",
  PleaseEnterNewGroupName: "請輸入新群組名稱",
  EnterNewGroupName: "輸入新群組名稱",
  TemplateName: "模板名稱",
  PleaseEnterANewTemplateName: "請輸入新模板名稱",
  EnterNewTemplateName: "輸入新模板名稱",
  renameSuccessfully: "重命名成功",
  CreateSuccessfully: "創建成功",
  addToGroup: "添加到群組",
  SelectChannelForNewTemplate: "為新模板選擇渠道",
  Role: "角色",
  Account: "帳戶",
  customFields: "自定義字段",
  contactsToStartEngagingWithThem: "聯絡人以開始與他們互動。",
  thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid:
    "此工作流程尚未有任何潛在客戶或線索。考慮添加",
  noProspectsAdded: "未添加潛在客戶",
  deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS:
    "投遞。目前，尚未通過該工作流程發送任何消息。",
  ensureEfficientCommunicationByMonitoringYourSequen:
    "通過監控您的工作流程消息來確保有效的溝通。",
  noMessagesHaveBeenSent: "尚未發送任何消息",
  yourNeedsGetStartedByClickingNewSequence:
    "滿足您的需求。通過點擊 [+ 新工作流程] 開始。",
  beginBuildingYourSalesStrategiesByDesigningSequenc:
    "通過設計符合您需求的工作流程開始構建銷售策略。",
  noSequencesCreatedYet: "尚未創建工作流程",
  leadActions: "潛在客戶操作",
  leadInformation: "潛在客戶信息",
  thisGroupHasNoTemplatesYet: "該組尚無模板",
  key: "{expr}",
  copyContent: "複製內容",
  default: "默認",
  selectVersion: "選擇版本",
  subject: "主題",
  searchTemplatesfolders: "搜索模板/文件夾",
  send: "發送",
  subject2: "主題：",
  enterASubjectOrLeaveItBlankToContinueInTheSameThre:
    "輸入主題或留空以繼續在同一線程中進行",
  chooseYourChannel: "選擇您的渠道",
  kl: "KL",
  addContacts: "添加聯絡人",
  addContactsToSequence: "將聯絡人添加到工作流程",
  addremoveCustomContactField: "添加/移除自定義聯絡人字段",
  contactCustomFields: "聯絡人自定義字段",
  type: "類型",
  typePhoneNumber: "輸入電話號碼",
  addAPhoneNumber: "添加電話號碼",
  fieldType: "字段類型",
  fieldLabel: "字段標籤",
  skipTheFollowingNationalHolidaysLaborDayIndependen:
    "跳過以下國定假日：勞動節、獨立日、紀念日、感恩節、聖誕前夕、聖誕節、新年。",
  checked: "已勾選",
  useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi:
    "如果聯絡人包含位置數據，則使用聯絡人的本地時區而不是日程的時區。",
  definePostreplyOrMeetingActionAndSequenceCompletio:
    "定義回覆後或會議行動及工作流程完成標準。",
  stopRepliesHandling: "停止回覆處理",
  timeZone: "時區：",
  country2: "國家",
  setMaxInitialEmailsDelaysEnabledisableOpensAndLink:
    "設置最大初始電子郵件、延遲、啟用/禁用打開和鏈接跟踪。",
  configuration: "配置",
  additionalInformation: "附加信息",
  connectALinkedinAccount: "連接LinkedIn帳戶",
  newLinkedinAccount: "新LinkedIn帳戶",
  feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR:
    '隨時可以重新訪問之前的任何步驟，以檢查或更新您的信息，然後再"啟動"工作流程。',
  template2: "模板：",
  next: "下一步",
  jacquelineLeung: "Jacqueline Leung",
  settings2: "設置",
  editThisTemplateScheduleFromThe: "從中編輯此模板日程",
  typeEmail: "輸入電子郵件",
  templateScheduling: "模板排程",
  launch: "啟動",
  linkedin: "LinkedIn",
  addAEmail: "添加電子郵件",
  addEmailAccount: "添加電子郵件帳戶",
  addStep: "添加步驟",
  newEmailAccount: "新電子郵件帳戶",
  craftYourLeadgenerationJourneyWithANewSequenceDefi:
    "使用新的工作流程打造您的潛在客戶生成旅程！定義您的目標受眾、消息和行動，以創建有效的潛在客戶生成工作流程。",
  craftYourCampaign: "打造您的活動",
  tailorYourOwnHoursToSuitSpecificSchedulingNeeds:
    "根據特定排程需求量身定制自己的時間。",
  personalizedScheduling: "個性化排程",
  requiredInformation: "所需信息",
  chooseEmailAccountsForSendingCampaignMessages:
    "選擇發送活動消息的電子郵件帳戶",
  accounts: "帳戶",
  enterSequenceName: "輸入工作流程名稱",
  thisPageEmpowersYouToTailorYourSchedulingSequenceS:
    "此頁面使您能夠根據您的偏好調整排程工作流程設置。",
  schedule: "排程",
  useThisOptionToAddContactsToTheSequenceYouHaveCrea:
    "使用此選項將聯絡人添加到您創建的工作流程中。",
  addProspects: "添加潛在客戶",
  save2: "保存",
  days: "天",
  days2: "天",
  within: "內部",
  opened: "已打開",
  day: "天",
  day2: "天",
  waitFor: "等待",
  sendTestEmailToMe: "發送測試電子郵件給我",
  create: "創建",
  enterANameForCustomContactField: "輸入自定義聯絡人字段的名稱",
  a: "A",
  byCancelingYouEnsureThatNoStepsWillBeDeleted:
    "通過取消，您可以確保不會刪除任何步驟",
  to2: "至",
  enterNameForNewVersion: "輸入新版本的名稱",
  activated: "已啟用",
  currentVersion: "當前版本",
  setTheNewVersionDefaultNow: "立即設置新版本為默認",
  enterNewName: "輸入新名稱",
  newVersion: "新版本",
  dontForgetToSetTheNewVersionDefaultOnceYouveFinish:
    "完成修改後，請勿忘記設置新版本為默認。",
  theDeletionOfThisConditionWillAlsoRemoveAllSubsequ:
    "刪除此條件將同時移除所有後續步驟。",
  pleaseEnterANewName2: "請輸入一個新名稱：",
  aNewVersionOfTheSequenceHasBeenCreated: "工作流程的新版本已創建。",
  youCanEitherEditTheCurrentVersionOrSaveItAndCreate:
    "您可以編輯當前版本或保存並創建一個新版本。",
  areYouSureYouWantToProceed: "您確定要繼續嗎？",
  newVersionName: "新版本名稱",
  sequenceStart: "工作流程開始",
  chooseActions: "選擇操作",
  chooseSteps: "選擇步驟",
  enterChannel2: "輸入渠道",
  chooseCategories: "選擇類別",
  htmlMode: "HTML模式",
  fromScratch: "從頭開始",
  sender: "發件人",
  finished: "完成",
  inProgress: "進行中",
  fromTemplate: "來自模板",
  newSequence: "新工作流程",
  nameOwner: "名稱擁有者",
  sort: "排序",
  ownedBy: "擁有者",
  starredOnly: "僅顯示星標",
  starred: "星標",
  box2: "箱子2",
  box1: "箱子1",
  add2: "+ 添加",
  lastSynced: "最後同步",
  hourlyLimit: "每小時限制",
  dailyLimit: "每日限制",
  account: "帳戶",
  skipTheFollowingNationalHolidaysLaborDayIndependen2:
    "跳過以下國定假日：勞動節、獨立日、紀念日、感恩節、聖誕前夕、聖誕節、新年。",
  useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2:
    "如果聯絡人包含位置數據，則使用聯絡人的本地時區而不是日程的時區。",
  saveChanges: "保存更改",
  emailSignature: "電子郵件簽名",
  reset2: "重置",
  pleaseNoteTheValueShouldBe0OrEmails: "請注意，值應該 >0 或 < {expr}",
  youHaveNoScheduledTemplateYet: "您尚未安排模板",
  discoverPremiumOptions: "發現高級選項",
  mailbox: "郵箱",
  sendingLimits: "發送限制",
  connect: "連接",
  joinOurCommunityOfPremiumUsersTransformingEmailEng:
    "加入我們的高級用戶社區，通過Pubrio轉變電子郵件互動並實現卓越的銷售成果。",
  enterYourEmailAddress: "輸入您的電子郵件地址",
  toJustMailbox: "僅發送到 {mailNum} 郵箱",
  yourCurrentBasicPlanAllowsAccess: "您當前的基本計劃允許訪問",
  setAsDefault2: "設為默認",
  createNewSchedule: "創建新日程",
  enterTemplateName: "輸入模板名稱",
  googleMeet: "Google Meet",
  emailPreview: "電子郵件預覽",
  addTemplate: "添加模板",
  simplifyYourWorkflowGroupYourSequenceTemplatesToKe:
    "簡化您的工作流程！將您的工作流程模板分組以保持組織，方便查找、編輯和根據需要使用。",
  startCreatingPersonalizedTemplatesForYourSequences:
    "開始為您的工作流程創建個性化模板！單擊「+」按鈕以創建新模板，輕鬆流暢地進行通信。",
  noTemplateYet: "尚無模板",
  preview: "預覽",
  creatNewGroup: "創建新群組",
  creatNewGroup2: "創建新群組",
  stayOrganizedWithYourSequenceTemplatesCreateGroups:
    "保持您的工作流程模板有序！創建群組以輕鬆管理和定位您的模板，使編輯和使用變得更加簡單。",
  noGroupsHaveBeenCreated: "尚未創建任何群組",
  creatNewTemplate: "創建新模板",
  addSequenceTemplateToTheGroup: "將工作流程模板添加到群組",
  byCancelingYouEnsureThatNoChangesAreMade:
    "通過取消，您可以確保不會進行任何更改",
  sequenceTemplates: "工作流程模板",
  youCanDeselectAndClickSaveToRemoveTheTemplateFromT:
    "您可以取消選擇並單擊「保存」以將模板從群組中移除",
  selectTemplateToAddToGroup: "選擇要添加到群組的模板",
  sequenceStatus: "工作流程狀態",
  sequenceStep: "工作流程步驟",
  newField: "新字段",
  enableTailoredDataEntryAndAdaptationToUniqueBusine:
    "啟用量身定制的數據輸入和適應獨特的業務流程",
  customContactFields: "自定義聯絡人字段",
  modality: "模式",
  enterADescriptionForCustomContactField: "輸入自定義聯絡人字段的描述",
  description2: "描述",
  enterADefaultValueForCustomContactField: "輸入自定義聯絡人字段的默認值",
  defaultValue: "默認值",
  enterAExampleForCustomContactField: "輸入自定義聯絡人字段的示例",
  example: "示例",
  enterPicklistoptionsForCustomContactField: "輸入自定義聯絡人字段的下拉選項",
  newCustomContactFields: "新的自定義聯絡人字段",
  engagementMetrics: "互動指標",
  message: "消息",
  to3: "至：",
  receiver: "接收者",
  claraHan: "Clara Han",
  createNewCategory: "創建新類別",
  bestRegards: "最好的問候，",
  thanksAgain: "再次感謝！",
  lookingForwardToAFruitfulCollaborationIfTheresAnyt:
    "期待富有成效的合作。如果有任何具體事項需要在開始之前解決，請隨時告訴我們。",
  jacquelineLeungProjectInCharge: "Jacqueline Leung（負責項目）",
  kingLaiAdviser: "King Lai（顧問）",
  iAppreciateTheIntroductionToOurMainContacts: "感謝您介紹我們的主要聯絡人：",
  contacted: "已聯絡",
  sendTestEmail: "發送測試電子郵件",
  thankYouSoMuchForTheWarmWelcomeWereEquallyExcitedA:
    "非常感謝您的熱情歡迎！我們同樣對與Pubrio GTM啟動項目和成為Venture AI團隊的一部分感到興奮。",
  hiJacqueline: "嗨，Jacqueline，",
  nov152023118Pm: "2023年11月15日，下午1:18",
  message2: "{total_messages} 訊息",
  topEcommerceAutomationPlatf: "頂級電子商務自動化平台...",
  cindyHsu: "Cindy Hsu",
  includeSignature: "包含簽名",
  ch: "CH",
  continueOnFail: "失敗時繼續",
  welcomeToPubrioGtm: "歡迎來到Pubrio GTM",
  replyToPreviousThread: "回覆之前的線程",
  sendAutomatic: "發送自動 {title}",
  searchByContacts: "按聯絡人搜索",
  processingErrorOccurredMaximumNumberOfUniqueContac:
    "處理錯誤：每月唯一聯絡人的最大數量已達到。",
  oct140954Pm: "10月14日，09:54 PM",
  shiftdragToMoveTheCanvas: "Shift+拖動以移動畫布",
  active: "活躍",
  inactive: "不活躍",
  archived: "已歸檔",
  archive: "歸檔",
  unarchive: "取消歸檔",
  archivedSuccessfully: "成功歸檔",
  unarchivedSuccessfully: "成功取消歸檔",
  sequenceArchivedSuccess: "工作流程成功歸檔",
  sequenceUnarchivedSuccess: "工作流程成功取消歸檔",
  alphabetical: "按字母順序",
  creationDate: "創建日期",
  viewDate: "查看日期",
  staredFirst: "首標星優先",
  SaveAsANewVersion: "另存為新版本",
  SaveCurrentVersion: "保存當前版本",
  schedules: "日程",
  EmailsSentPerDay: "每日發送的電子郵件",
  EmailsSentPerHour: "每小時發送的電子郵件",
  DelayBetweenEachEmailSent: "每封電子郵件發送之間的延遲（秒）",
  RecommendedDailyLimit:
    "建議每日限制：50-100封電子郵件，只有在發送高度個性化的活動且保持強大的域名聲譽時，才可例外達到100封以上。",
  RecommendedHourlyLimit:
    "建議每小時限制：將電子郵件數量保持在100封以下。每小時不得超過120封電子郵件。",
  currentDelayAllows: "當前延遲允許您每小時最多發送120封電子郵件。",
  SetAsDefault: "設為默認",
  monday: "星期一",
  tuesday: "星期二",
  wednesday: "星期三",
  thursday: "星期四",
  friday: "星期五",
  saturday: "星期六",
  sunday: "星期日",
  Templates: "模板",
  Analytics: "分析",
  Meetings: "會議",
  Settings: "設置",
  EnterTemplateName: "輸入模板名稱",
  EnterGroupName: "輸入群組名稱",
  ExportAllData: "導出所有數據",
  ExportAsCSV: "導出為CSV",
  Total: "總計",
  Opened: "已打開",
  Clicked: "已點擊",
  NotOpened: "未打開",
  Bounced: "退回",
  Replied: "已回覆",
  Failed: "失敗",
  Inbox: "收件箱",
  Report: "報告",
  Log: "日誌",
  UseThisTemplate: "使用此模板",
  UseThisFolder: "使用此文件夾",
  UpdateThisTemplate: "更新此模板",
  RemoveFromGroup: "從群組中移除",
  DeleteThisTemplate: "刪除此模板",
  MarkAsFinished: "標記為完成",
  Remove: "移除",
  Pause: "暫停",
  Resume: "恢復",
  AddToSequence: "添加到工作流程",
  Export: "導出",
  InProgress: "進行中",
  Finished: "完成",
  Paused: "已暫停",
  Engaged: "參與中",
  Reply: "回覆",
  NotSent: "未發送",
  MaxNumberOfEmailsPerDay: "每日最大電子郵件數",
  MaxNumberOfEmailsPerHour: "每小時最大電子郵件數",
  EnableOpensTracking: "啟用打開跟踪",
  EnableReplyTracking: "啟用回覆跟踪",
  EnableClickTracking: "啟用點擊跟踪",
  ReplyByMessage: "通過消息回覆",
  BookAMeeting: "預訂會議",
  ClickOnLink: "點擊鏈接",
  CreateManually: "手動創建",
  AddContactsToTheSequenceManually: "手動將聯絡人添加到工作流程，一個一個。",
  ImportFromCSV: "從CSV導入",
  UploadCSVFileToAddContactsToSequence: "上傳CSV文件以將聯絡人添加到工作流程。",
  AddFromExisting: "從現有中添加",
  AddExistingContactsIntoTheSequence: "將現有聯絡人添加到工作流程。",
  ChooseFile: "選擇文件",
  MapAttributes: "映射屬性",
  Options: "選項",
  ReviewAndImport: "審核並導入",
  UpdateMissingFields: "更新缺失字段",
  DetectContactTimeZoneAutomatically: "自動檢測聯絡人時區",
  account_website: "帳戶網站",
  linkedin_url: "LinkedIn網址",
  raw_address: "原始地址",
  first_name: "名字",
  last_name: "姓氏",
  account_name: "帳戶名稱",
  Identifier: "識別碼",
  firstName: "名字",
  lastName: "姓氏",
  EnterJobTitleOrPosition: "輸入職位名稱或職位",
  EnterCompanyName: "輸入公司名稱",
  EnterYourCustomerID: "輸入您的客戶ID",
  EnterCompanyWebsiteURL: "輸入公司網站URL",
  EnterLinkedInProfileURL: "輸入LinkedIn個人資料URL",
  EnterCity: "輸入城市",
  versionName: "版本名稱",
  PleaseEnterANewVersionName: "請輸入新版本名稱",
  EnterNewVersionName: "輸入新版本名稱",
  versions: "版本",
  SequenceNameUpdatedSuccessfully: "工作流程名稱更新成功",
  SequenceNameCannotBeEmpty: "工作流程名稱不能為空",
  delete_success: "刪除成功",
  delete_fail: "刪除失敗",
  set_default_success: "設置默認成功",
  DuplicateVersionSuccessfully: "成功複製版本",
  CreateNewVersionSuccessfully: "成功創建新版本",
  UpdateSuccessfully: "更新成功",
  AddToGroupSuccessfully: "成功添加到群組",
  sendAutomaticEmail: "發送自動電子郵件",
  sendMessage: "發送消息",
  uploadFailed: "上傳失敗",
  InvalidEmailFormat: "電子郵件格式無效",
  FailedToSetUpEmailAccount: "設置電子郵件帳戶失敗",
  noAccount: "沒有帳戶",
  addAnAccountToPersonalizYourExperienceAndUnlock:
    "添加帳戶以個性化您的體驗並解鎖高級功能",
  trackingFeaturesForThisSequence: "此工作流程的跟踪功能。",
  UpdatedSuccessfully: "更新成功",
  update_fail: "更新失敗",
  connectYourGoogleMeetAccountToPubrioConversations:
    "將您的Google Meet帳戶連接到Pubrio會話",
  set_default_fail: "設置默認失敗",
  duplicatedSuccessfully: "成功複製",
  noStepsInSequencePleaseAddStepsFirst: "工作流程中沒有步驟，請先添加步驟。",
  addLabelSuccessfully: "成功添加標籤",
  PleaseEnterSequenceName: "請輸入工作流程名稱",
  sequenceNameCannotBeEmpty: "工作流程名稱不能為空",
  sequenceNameUpdatedSuccessfully: "工作流程名稱更新成功",
  createStepFlowSuccessfully: "成功創建步驟流程",
  updateStepFlowSuccessfully: "成功更新步驟流程",
  testEmailSentSuccessfully: "測試電子郵件發送成功",
  removeEmailAccountSuccessfully: "成功移除電子郵件帳戶",
  pleaseSelectTheDataToBeExported: "請選擇要導出的數據",
  confirm: "確認",
  saveFailed: "保存失敗",
  deleteThisStep: "刪除此步驟",
  Email: "電子郵件",
  LinkedIn: "LinkedIn",
  API: "API",
  Task: "任務",
  Custom: "自定義",
  proceedImmediately: "立即進行",
  dayss: "天",
  hourss: "小時",
  minutess: "分鐘",
  theConditionAndAllTheStepsThatFollowFromItWillBeDeleted:
    "該條件及其後所有步驟將被刪除，您確定要繼續嗎？",
  sendConnectionRequest: "發送連接請求",
  viewProfile: "查看個人資料",
  callAnAPI: "調用API",
  createAManualTask: "創建手動任務",
  hasEmailAddress: "有電子郵件地址",
  hasLinkedInURL: "有LinkedIn網址",
  hasPhoneNumber: "有電話號碼",
  customCondition: "自定義條件",
  openedEmail: "已打開的電子郵件",
  clickOnLinkInEmail: "點擊電子郵件中的鏈接",
  unsubscribeFromEmail: "退訂電子郵件",
  bookAMeeting: "預訂會議",
  acceptedInvite: "接受邀請",
  conditions: "條件",
  Yes: "是",
  No: "否",
  newVariable: "新變量",
  selectVariable: "選擇變量",
  ifLeadVariable: "如果潛在客戶變量 ",
  condition: "條件",
  selectCondition: "選擇條件",
  contains: "包含",
  is_equal_to: "等於",
  is_empty: "為空",
  is_not_empty: "不為空",
  picture: "圖片",
  company_domain: "公司域名",
  company_name: "公司名稱",
  icebreaker: "破冰話題",
  change_steps_conditions: "更改步驟/條件",
  ForceASpecificSenderForThisStep: "強制此步驟使用特定發件人",
  method: "方法",
  selectASender: "選擇發件人",
  assignAPITaskTo: "將API任務分配給",
  enterCompanyNamesAndPressEnterKey: "輸入公司名稱並按Enter鍵",
  feelFreeToEditTheContentAndSaveYourChangesDirectly:
    "隨時編輯內容並在點擊後直接保存更改。",
  permission: "權限",
  addAnyAdditionalStepsOrConditionsAsNeeded: "根據需要添加任何其他步驟或條件",
  reviewAndFinalizeSequenceSettingsAndPreferences:
    "審核並最終確定工作流程設置和偏好",

  // setting
  nameOnCard: "卡片上的姓名",
  expiryDate: "到期日期",
  cvccvv: "CVC/CVV",
  details: "詳情",
  notification: "通知",
  markAsAllRead: "標記為全部已讀",
  Personal: "個人",
  profile: "個人資料",
  extensions: "擴展功能",
  notifications: "通知",
  Company: "公司",
  roles: "角色",
  teams: "團隊",
  "current-usage": "當前使用情況",
  "upgrade-plan": "升級計劃",
  Products: "產品",
  APIKeys: "API金鑰",
  popular: "熱門",
  priceSequence: "工作流程（即將推出）",
  free: "免費計劃",
  lite: "輕量計劃",
  growth: "初創計劃",
  business: "商務計劃",
  enterEmailAddressesMultipleCommaSeparated:
    "輸入電子郵件地址（多個，以逗號分隔）",
  sharingRulesSelectTeamToEnableThisField: "共享規則（選擇團隊以啟用此字段）",
  allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges:
    "所有座位目前已被佔用。任何需要付費座位的新用戶將產生額外費用。",
  dueToday: "今天到期",
  costPerBillingCycle: "每個計費週期的成本",
  "select...": "選擇...",
  invite: "邀請",
  services: "企業服務",
  "services-group": "服務組",
  "all-notification-services": "所有通知服務",
  "services-status-update": "服務狀態更新",
  "system-group": "系統組",
  "all-notification-system": "所有通知系統",
  "system-new-feature-release": "系統新功能發布",
  "system-news-letter": "系統新聞信",
  "system-generic-alarm": "系統通用警報",
  markAsRead: "標記為已讀",
  showLess: "顯示較少",
  showMore: "顯示更多",
  changedStatusTo: "狀態已更改為",
  "access-management-group": "訪問管理組",
  "access-management-roles": "訪問管理角色",
  "access-management-create-roles": "訪問管理創建角色",
  "access-management-edit-user-roles": "訪問管理編輯用戶角色",
  "access-management-add-role-to-users": "訪問管理將角色添加到用戶",
  "access-management-delete-users-role": "訪問管理刪除用戶角色",
  "access-management-delete-roles": "訪問管理刪除角色",
  "access-management-read-roles-and-role-users": "訪問管理讀取角色及其用戶",
  "access-management-teams": "訪問管理團隊",
  "access-management-delete-users-team": "訪問管理刪除用戶團隊",
  "access-management-read-teams-sharing-rules-teams-users":
    "訪問管理讀取團隊共享規則及其用戶",
  "access-management-create-teams": "訪問管理創建團隊",
  "access-management-edit-teams-sharing-rules": "訪問管理編輯團隊共享規則",
  "access-management-delete-teams-sharing-rules": "訪問管理刪除團隊共享規則",
  "access-management-add-and-update-team-to-users":
    "訪問管理將團隊添加並更新至用戶",
  "access-management-read-team-relations": "訪問管理讀取團隊關係",
  "access-management-modify-team-relations": "訪問管理修改團隊關係",
  "intent-signal-group": "意圖信號組",
  "market-intent-signal": "市場意圖信號",
  "web-intent-signal": "網絡意圖信號",
  "web-intent-signal-add-and-activate": "網絡意圖信號添加和激活",
  "web-intent-signal-read-data": "網絡意圖信號讀取數據",
  "web-intent-signal-export-data": "網絡意圖信號導出數據",
  "web-intent-signal-delete-data": "網絡意圖信號刪除數據",
  "web-intent-signal-read-analytic": "網絡意圖信號讀取分析",
  "search-group": "搜索組",
  "company-search": "公司搜索",
  "prospects-search": "潛在客戶搜索",
  "sequence-group": "工作流程組",
  sequences: "工作流程",
  "service-create-request": "服務創建請求",
  "service-update-request": "服務更新請求",
  "service-read-request": "服務讀取請求",
  "service-delete-service-attachment": "服務刪除服務附件",
  "service-delete-service-message": "服務刪除服務消息",
  "service-send-and-edit-service-message": "服務發送和編輯服務消息",
  "service-upload-service-attachment": "服務上傳服務附件",
  "settings-group": "設置組",
  "custom-fields": "自定義字段",
  "user-management-and-billing-group": "用戶管理和計費組",
  "billing-read-credit-card": "計費讀取信用卡",
  "billing-download-invoice": "計費下載發票",
  "billing-read-invoice": "計費讀取發票",
  "credit-usage": "信用點數使用",
  "credit-usage-read-profile-detail": "信用點數使用讀取個人資料詳情",
  "credit-usage-read-profile-usage": "信用點數使用讀取個人資料使用情況",
  "profile-and-team": "個人資料與團隊",
  "profile-and-team-invite-team-member": "個人資料與團隊邀請團隊成員",
  "profile-and-team-update-profile": "個人資料與團隊更新個人資料",
  "profile-and-team-delete-team-member": "個人資料與團隊刪除團隊成員",
  "profile-and-team-get-user-secret-token": "個人資料與團隊獲取用戶密鑰令牌",
  "profile-and-team-update-user-and-password": "個人資料與團隊更新用戶及密碼",
  "profile-and-team-read-profile-users": "個人資料與團隊讀取個人資料用戶",
  "upgrade-plan-read-subscription": "升級計劃讀取訂閱",
  "upgrade-plan-upgrade-subscription": "升級計劃升級訂閱",
  "all-access-access-management-roles": "所有訪問權限管理角色",
  "all-access-access-management-teams": "所有訪問權限管理團隊",
  "all-access-market-intent-signal-page": "所有訪問市場意圖信號頁面",
  "all-access-web-intent-signal-page": "所有訪問網絡意圖信號頁面",
  "all-access-company-search-page": "所有訪問公司搜索頁面",
  "all-access-prospects-search-page": "所有訪問潛在客戶搜索頁面",
  "all-access-sequence-page": "所有訪問工作流程頁面",
  "all-access-service-page": "所有訪問服務頁面",
  "all-access-custom-fields-page": "所有訪問自定義字段頁面",
  "all-access-billing-page": "所有訪問計費頁面",
  "all-access-credit-usage-page": "所有訪問信用點數使用頁面",
  "all-access-profile-and-team-page": "所有訪問個人資料與團隊頁面",
  newPermissionProfile: "新權限配置檔",
  enterAName: "輸入名稱",
  addUserToRole: "將用戶添加到角色",
  changePermission: "更改權限",
  selectPermissionProfiles: "選擇權限配置檔",
  backToRoles: "返回角色",
  updateRole: "更新角色",
  enterADescription: "輸入描述",
  createANewRole: "創建新角色",
  AreYouSureYouWantToRemoveThisRole: "您確定要移除此角色嗎？",
  AreYouSureYouWantToRemoveThisUserFromTheTeam:
    "您確定要將此用戶從團隊中移除嗎？",
  ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser:
    "進行刪除將永久移除該用戶的角色。",
  ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam:
    "進行刪除將永久移除該用戶從團隊中。",
  relation: "關係",
  newTeam: "新團隊",
  "full-sharing-market-intent-signal-data": "完整共享市場意圖信號數據",
  "full-sharing-web-intent-signal-data": "完整共享網絡意圖信號數據",
  "full-sharing-company-search-data": "完整共享公司搜索數據",
  "full-sharing-prospects-search-data": "完整共享潛在客戶搜索數據",
  "full-sharing-sequence-data": "完整共享工作流程數據",
  "full-sharing-service-data": "完整共享服務數據",
  "share-service-attachments": "共享服務附件",
  "share-service-messages": "共享服務消息",
  RenameSharingRules: "重命名共享規則",
  enterNewSharingRulesNameBelow: "在下方輸入新共享規則名稱：",
  enterNewSharingRulesName: "輸入新共享規則名稱",
  enterNewSharingRulesDescriptionBelow: "在下方輸入新共享規則描述：",
  enterNewSharingRulesDescription: "輸入新共享規則描述",
  enterSharingRulesName: "輸入共享規則名稱",
  currentUsers: "當前用戶",
  pendingUsers: "待處理用戶",
  addRole: "添加角色",
  addTeam: "添加團隊",
  addPermission: "添加權限",
  "Re-invite": "重新邀請",
  relations: "關係",
  "is-parent-of": "是...的父項",
  addRelation: "添加關係",
  deleteRelation: "刪除關係",
  updateRelation: "更新關係",
  API_Keys: "API金鑰",
  createNewKey: "創建新金鑰",
  createAPIKey: "創建API金鑰",
  APIs: "API",
  setAsMasterKey: "設置為主金鑰",
  "growth-annual": "增長-年度",
  business: "商業",
  "website-intent-data": "網站意圖數據",
  "demo-identification": "演示識別",

  activateTheNewVersionNow: "立即啟用新版本",
  feedbackSendSuccessfully: "反饋已成功發送",
  feedbackSendFailed: "反饋發送失敗",
  autoSave: "自動保存",
  serviceDescShowMore: "顯示更多",
  serviceDescShowLess: "顯示較少",
  members: "成員",
  shareTo: "分享至...",
  findTeamMembers: "尋找團隊成員",
  select: "選擇",
  whenBalanceFallsBelow: "當餘額低於",
  defaultPaymentMethod: "默認付款方式",
  creditCardDetails: "信用卡詳細信息",
  automaticallyTopUp: "自動充值",
  autoTopupWillAutomaticallyChargeYourPaymentMethodT:
    "自動充值將自動向您的付款方式收費。要禁用此功能，請保持此框未勾選。",
  enableAutoTopups: "啟用自動充值",
  validThru: "有效期至",
  currentBalance: "當前餘額",
  cardHolder: "持卡人姓名",
  gotIt: "明白了",
  pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA:
    "請確保您的付款方式是最新的，並且在啟用之前您對此自動過程感到滿意。",
  thresholdCredits: "閾值：{creditLimit} 信用點數",
  creditAmountCredits: "充值金額：{choosedTopUpCredit} 信用點數",
  enablingAutoTopupsWillAutomaticallyAddCreditsToYou:
    "啟用自動充值將在您的餘額低於指定閾值時自動向您的帳戶添加信用點數。這可能會導致對您選擇的付款方式進行自動收費。",
  thisWillAutomaticallyAddCreditsToYourAccountWhenev:
    "{choosedTopUpCredit} 信用點數將在您的餘額低於 {creditLimit} 信用點數時自動添加到您的帳戶。",
  autoTopupNotice: "自動充值通知",
  roleName: "角色名稱",
  addUser: "添加用戶",
  newRole: "新角色",
  userName: "用戶名稱",
  joined: "已加入",
  thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow:
    "此角色目前沒有分配任何用戶。開始添加團隊成員以定義他們的責任並簡化工作流程。",
  noUsersAssigned: "未分配用戶",

  addUserToTeam: "添加用戶到團隊",
  user: "用戶",
  sharingRuleGroup: "共享規則組",
  updateTeam: "更新團隊",
  createANewTeam: "創建新團隊",
  backToTeams: "返回團隊",
  listOfSharingRules: "共享規則列表",
  sharingRules: "共享規則",
  manageSharingRules: "管理共享規則",
  CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules:
    "創建和自定義共享規則以控制不同用戶的訪問權限。單擊下面的按鈕以添加新的共享規則。",
  newSharingRules: "新共享規則",

  // price
  exportLimit: "導出上限",
  customRolePermission: "自定義角色權限",
  teamManagement: "團隊管理",
  API: "API",
  linkedinAccount: "LinkedIn 帳戶",
  perPageLimit: "每頁上限",
  recordUploadLimit: "記錄上傳上限",
  creditRollover: "信用點數結轉",
  quotaSharing: "配額共享",
  quotaAdjustment: "配額調整",
  add_On: "附加功能",
  topUpCredits: "充值信用點數",
  webVisitorIdentification: "網絡訪客識別",
  dataExportCredit: "數據導出",
  companySearchCredit: "公司搜索",
  peopleSearchCredit: "潛在客戶搜索",
  emailRedeemCredit: "電子郵件搜索",
  phoneNumberRedeemCredit: "電話號碼搜索",
  recordSelection: "記錄選擇",
  portal: "平台",
  queryLimitPerHour: "每小時查詢上限",
  queryLimitPerDay: "每日查詢上限",
  callLimitPerHour: "每小時請求上限",
  callLimitPerDay: "每日請求上限",
  callLimitPerMonth: "每月請求上限",
  basicSequencing: "基本工作流程設置",
  numberOfSequences: "工作流程數量",
  numberOfMailboxesPerUser: "每個用戶的郵箱數量",
  numberOfLinkedinAccountPerUser: "每個用戶的LinkedIn帳戶數量",
  numberOfSlackAccountPerUser: "每個用戶的Slack帳戶數量",
  automatedFollowUp: "自動跟進",
  advancedSequencing: "進階工作流程設置",
  abTesting: "A/B測試",
  multipleEmailTemplates: "多個電子郵件模板",
  reporting: "報告",
  emailOpenTracking: "電子郵件打開跟踪",
  emailClickTracking: "電子郵件點擊跟踪",
  emailReplyTracking: "電子郵件回覆跟踪",
  support: "支持",
  priorityEmailSupport: "優先電子郵件支持",
  companySelectionLimit: "公司選擇上限",
  prospectSelectionLimit: "潛在客戶選擇上限",
  totalPageLimit: "總頁面上限",
  pricingYear: "年",
  "growth-annual": "初創計劃",
  "business-annual": "商務計劃",
  pricingSearch: "搜索",
  pricingSearches: "搜索次數",
  pricingMonth: "月",
  topupCredit: "充值信用點數",
  perUserPerYear: "每位用戶，每月\n按年計費",
  perUserPerMonth: "每位用戶，每月\n按月計費",
  customCredits: "自定義信用點數",
  customSearches: "自定義搜索",
  queryLimitPerMonth: "每月查詢上限",
  customized: "自定義",
  custom: "自定義 ",
  free: "免費計劃",
  growth: "初創計劃",
  business: "商務計劃",
  organization: "定制方案",
  search: "數據搜索",

  // setting
  pleaseSelectTheUserYouWantToAdd: "請選擇您想添加的用戶",
  pleaseSelectTheUserYouWantToDelete: "請選擇您想刪除的用戶",
  pleaseSelectTheUserYouWantToAddPermission: "請選擇您想添加權限的用戶",

  ...sequence,
  redirectSoon: "即將跳轉",
  ...notificationTraditional,

  ...base,
  ...search,
  ...services,
  ...intent,
  ...tableEmptyInfomationCNTraditional,
  ...settingApiKeysCNTraditional,
  ...globalMessageChineseTraditional,
  ...pricing,
};

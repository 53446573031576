const tableEmptyInfomationEnglish = {
  "-table-empty-title": "No Data Yet",
  "-table-empty-desc": "",
  "sequence-list-table-empty-title": "No Sequences Created Yet",
  "sequence-list-table-empty-desc":
    "Begin building your sales strategies by designing sequences tailored to your needs. Get started by clicking [+ New Sequence]",
  "sequence-detail-inbox-table-empty-title": "No messages have been sent",
  "sequence-detail-inbox-table-empty-desc":
    "Ensure efficient communication by monitoring your sequence's message delivery. Currently, no messages have been sent through the sequence.",
  "setting-notification-table-empty-title": "No Notifications Yet",
  "setting-notification-table-empty-desc":
    "Looks like there's nothing new for now. Check back later for updates and notifications.",
  "setting-billing-history-table-empty-title": "No Billing History Yet",
  "setting-billing-history-table-empty-desc":
    "It looks like you haven’t made any payments yet. Once you start using our paid features, your billing history will appear here.",
  "setting-role-table-empty-title": "No Roles Created",
  "setting-role-table-empty-desc":
    "Roles help you manage permissions within your team. Create roles to define access levels for your users.",
  "setting-roles-users-table-empty-title": "No Users Assigned",
  "setting-roles-users-table-empty-desc":
    "This role currently has no users assigned to it. Start adding team members to define their responsibilities and streamline your workflow.",
  "setting-team-table-empty-title": "No Teams Created",
  "setting-team-table-empty-desc":
    "Teams allow you to organize and collaborate with different groups. Start by creating a new team to get started.",
  "setting-teams-users-table-empty-title": "No Users Assigned",
  "setting-teams-users-table-empty-desc":
    "This role currently has no users assigned to it. Start adding team members to define their responsibilities and streamline your workflow.",
  "setting-users-table-empty-title": "No Users Added",
  "setting-users-table-empty-desc":
    "Users will appear here once added. You can invite team members to join your platform for collaborative work.",
  "setting-custom-field-table-empty-title": "No Custom Contact Fields Yet",
  "setting-custom-field-table-empty-desc": "",
  "setting-api-keys-table-empty-title": "No API Keys Generated",
  "setting-api-keys-table-empty-desc":
    "API keys allow you to integrate with external services. Create a new API key to start connecting your system.",
};

export default tableEmptyInfomationEnglish;
